import publicApi from './modules/public'
import loginAndRegister from './modules/loginAndRegister' // 登录注册
import profile from './modules/profile'
import group from './modules/group'
import chat from './modules/chat' // 聊天室
import home from './modules/home'
import channel from './modules/channel'
import course from './modules/course'
import wallet from './modules/wallet'
import donation from './modules/donation'
import project from './modules/project' // 项目
import meeting from './modules/meeting' // 会议
import event from './modules/event' // 活动
import senator from './modules/senator' // 参议院
import OrderManagement from './modules/OrderManagement' // 订单管理
import newEvent from './modules/newEvent' // 订单管理
import pointsMall from './modules/pointsMall'
import program from './modules/program'
import skill from './modules/skill'
import projects from './modules/projects'

const http = {
  ...publicApi,
  ...loginAndRegister,
  ...profile,
  ...group,
  ...chat,
  ...home,
  ...project,
  ...channel,
  ...course,
  ...wallet,
  ...meeting,
  ...event,
  ...donation,
  ...senator,
  ...OrderManagement,
  ...newEvent,
  ...pointsMall,
  ...program,
  ...skill,
  ...projects
}

const install = function (Vue) {
  Vue.prototype.$http = http
}

export default install
