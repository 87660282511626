import request from '../request'
export default {
  // 支付成功改变状态
  meetingPayPalCheckoutSuccess(data) {
    return request({
      url: "/api/meetingPayPalCheckoutSuccess",
      method: "POST",
      data
    });
  },
  // 获取会议列表
  meetingList (data) {
    return request({
      url: '/api/meetingList',
      method: 'POST',
      data
    })
  },
  // 会议详情
  meetingDetail (data) {
    return request({
      url: '/api/meetingDetail',
      method: 'POST',
      data
    })
  },
  // 会议参加
  meetingAttend (data) {
    return request({
      url: '/api/meetingAttend',
      method: 'POST',
      data
    })
  },

  // 参与者列表
  // meetingParticipant (data) {
  //   return request({
  //     url: '/api/meetingParticipant',
  //     method: 'POST',
  //     data
  //   })
  // },

  // 会议取消
  // meetingCancel (data) {
  //   return request({
  //     url: '/api/meetingCancel',
  //     method: 'POST',
  //     data
  //   })
  // },
  // 会议签到（线下会议）
  // meetingCode (data) {
  //   return request({
  //     url: '/api/meetingCode',
  //     method: 'POST',
  //     data
  //   })
  // },

  // 我的会议
  // myMeeting (data) {
  //   return request({
  //     url: '/api/myMeeting',
  //     method: 'POST',
  //     data
  //   })
  // },

  // 会议套餐列表
  meetingPackageList (data) {
    return request({
      url: '/api/meetingPackageList',
      method: 'POST',
      data
    })
  },

  // 获取会议各类行程
  meetingProgram (data) {
    return request({
      url: '/api/meetingProgram',
      method: 'POST',
      data
    })
  },

  // 添加会议行程到日程表
  meetingAddProgram (data) {
    return request({
      url: '/api/meetingAddProgram',
      method: 'POST',
      data
    })
  },

  // 删除会议日程表的行程
  meetingDeleteProgram (data) {
    return request({
      url: '/api/meetingDeleteProgram',
      method: 'POST',
      data
    })
  },

  // 会议问卷详情
  meetingQuestionnaireContent (data) {
    return request({
      url: '/api/meetingQuestionnaireContent',
      method: 'POST',
      data
    })
  },

  // 会议问卷提交
  meetingQuestionnaireSubmit (data) {
    return request({
      url: '/api/meetingQuestionnaireSubmit',
      method: 'POST',
      data
    })
  },

  // 添加会议评论
  meetingAddComment (data) {
    return request({
      url: '/api/meetingAddComment',
      method: 'POST',
      data
    })
  },

  // 删除会议评论
  meetingDeleteComment (data) {
    return request({
      url: '/api/meetingDeleteComment',
      method: 'POST',
      data
    })
  },

  // 会议选项卡切换
  meetingTab (data) {
    return request({
      url: '/api/meetingTab',
      method: 'POST',
      data
    })
  },

  // 判断用户是否有权力点击链接
  meetingUserHasPowerClickLink (data) {
    return request({
      url: '/api/meetingUserHasPowerClickLink',
      method: 'POST',
      data
    })
  },
  // 生成订单
  meetingPayPackage (data) {
    return request({
      url: '/api/meetingPayPackage',
      method: 'POST',
      data
    })
  },
  // 信用卡
  meetingRecharge (data) {
    return request({
      url: '/api/meetingRecharge',
      method: 'POST',
      data
    })
  },
  // 轮寻
  meetingPolling (data) {
    return request({
      url: '/api/meetingPolling',
      method: 'POST',
      data
    })
  },


  // 获取订单列表
  meetingOrderList (data) {
    return request({
      url: '/api/meetingOrderList',
      method: 'POST',
      data
    })
  },

  // 获取会议订单详情
  meetingOrderDetail (data) {
    return request({
      url: '/api/meetingOrderDetail',
      method: 'POST',
      data
    })
  },

  // 删除会议订单
  delMeetingOrder (data) {
    return request({
      url: '/api/delMeetingOrder',
      method: 'POST',
      data
    })
  },

  // 取消会议订单
  cancelMeetingOrder (data) {
    return request({
      url: '/api/cancelMeetingOrder',
      method: 'POST',
      data
    })
  },

  // 会议订单申请退款
  applyMeetingRefund (data) {
    return request({
      url: '/api/applyMeetingRefund',
      method: 'POST',
      data
    })
  },


}