import request from '../request'
export default {
  // 创建会员订单
  vipCreateOrder (data) {
    return request({
      url: '/api/vipCreateOrder',
      method: 'POST',
      data
    })
  },
  // 申请资格
  addAccountMembership (data) {
    return request({
      url: '/api/addAccountMembership',
      method: 'POST',
      data
    })
  },
  // 验证支付密码
  verifyPaymentPassword (data) {
    return request({
      url: '/api/verifyPaymentPassword',
      method: 'POST',
      data,
      encryptionType: 2
    })
  },
  // 忘记支付密码发送邮件
  forgotPaymentPassword (data) {
    return request({
      url: '/api/forgotPaymentPassword',
      method: 'POST',
      data
    })
  },
  // 重置支付密码
  resetPaymentPassword (data) {
    return request({
      url: '/api/resetPaymentPassword',
      method: 'POST',
      data
    })
  },
  // 议员标签
  getSenateApply (data) {
    return request({
      url: '/api/getSenateApply',
      method: 'POST',
      data
    })
  },
  // 扫码绑定 2fa 验证
  bindGoogleToken (data) {
    return request({
      url: '/api/bindGoogleToken',
      method: 'POST',
      data
    })
  },
  // 获取二维码字符串
  getGoogleQrcode (data) {
    return request({
      url: '/api/getGoogleQrcode',
      method: 'POST',
      data
    })
  },
  // 修改密码
  updatePassword (data) {
    return request({
      url: '/api/updatePassword',
      method: 'POST',
      data,
      encryptionType: 2
    })
  },
  /// //////////////////////// 个人技能 /////////////////////
  // 新增个人技能
  addSkill (data) {
    return request({
      url: '/api/addSkill',
      method: 'POST',
      data
    })
  },
  // 删除个人技能
  delSkill (data) {
    return request({
      url: '/api/delSkill',
      method: 'POST',
      data
    })
  },
  // 个人技能列表
  skillList (params) {
    return request({
      url: '/api/skillList',
      method: 'GET',
      params
    })
  },
  // 个人技能列表
  getNewUserInfo (params) {
    return request({
      url: '/api/getNewUserInfo',
      method: 'GET',
      params
    })
  },
  // 推荐技能
  recommendSkill (data) {
    return request({
      url: '/api/recommendSkill',
      method: 'POST',
      data
    })
  },
  // 个人技能分类列表               // => 暂未调用
  SkillClassificationList (data) {
    return request({
      url: '/api/SkillClassificationList',
      method: 'POST',
      data
    })
  },
  /// //////////////////// 项目经验  ///////////////////
  // 新增项目经验
  userAddProject (data) {
    return request({
      url: '/api/userAddProject',
      method: 'POST',
      data
    })
  },
  // 删除项目经验                  // => 暂未调用
  userDelProject (data) {
    return request({
      url: '/api/userDelProject',
      method: 'POST',
      data
    })
  },
  // 编辑项目经验
  userEditProject (data) {
    return request({
      url: '/api/userEditProject',
      method: 'POST',
      data
    })
  },
  // 项目经验列表
  listUserProject (params) {
    return request({
      url: '/api/listUserProject',
      method: 'GET',
      params
    })
  },
  // 项目经验列表
  userDelProject (data) {
    return request({
      url: '/api/userDelProject',
      method: 'POST',
      data
    })
  },
  // 获取单条项目经验
  projectExperienceFind (data) {
    return request({
      url: '/api/projectExperienceFind',
      method: 'POST',
      data
    })
  },
  /// //////////////////// 工作经验  ///////////////////
  // 就业类型列表
  employmentTypeList (data) {
    return request({
      url: '/api/employmentTypeList',
      method: 'POST',
      data
    })
  },
  // 删除工作经验      ////// ///// => 暂未调用
  userDelWork (data) {
    return request({
      url: '/api/userDelWork',
      method: 'POST',
      data
    })
  },
  // 编辑工作经验
  userEditWork (data) {
    return request({
      url: '/api/userEditWork',
      method: 'POST',
      data
    })
  },
  // 新增工作经验
  userAddWork (data) {
    return request({
      url: '/api/userAddWork',
      method: 'POST',
      data
    })
  },
  // 工作经验列表
  listWorkExperience (params) {
    return request({
      url: '/api/listWorkExperience',
      method: 'GET',
      params
    })
  },
  // 行业列表
  IndustryList (data) {
    return request({
      url: '/api/IndustryList',
      method: 'POST',
      data
    })
  },
  // 图片上传
  uploadImage (data) {
    return request({
      url: '/api/uploadImage',
      method: 'POST',
      data
    })
  },
  // 获取单条工作经验信息
  firstWorkExperience (params) {
    return request({
      url: '/api/firstWorkExperience',
      method: 'GET',
      params
    })
  },
  // 获取历史工作经验列表
  getAllWork (data) {
    return request({
      url: '/api/getAllWork',
      method: 'POST',
      data
    })
  },

  /// //////////////////// 用户信息  ///////////////////
  // 2fa 问题列表
  problemsList (data) {
    return request({
      url: '/api/problemsList',
      method: 'POST',
      data
    })
  },
  // 设置2fa 登录问题
  login2faProblemAdd (data) {
    return request({
      url: '/api/login2faProblemAdd',
      method: 'POST',
      data
    })
  },
  // 2fa 开启或关闭
  updateUserData (data) {
    return request({
      url: '/api/updateUserData',
      method: 'POST',
      data
    })
  },
  // 2fa 关闭发送邮件
  off2faEmail (data) {
    return request({
      url: '/api/off2faEmail',
      method: 'POST',
      data
    })
  },
  //  token 换取用户信息
  getUserInfo (data) {
    return request({
      url: '/api/getUserInfo',
      method: 'POST',
      data,
      encryptionType: 3
    })
  },

  jciAuthorize (data) {
    return request({
      url: '/api/jciAuthorize',
      method: 'POST',
      data,
      encryptionType: 3
    })
  },

  // 获取新项目token
  oauthToken (data) {
    return request({
      // baseURL: 'https://mc-api.jybtx.com',
      baseURL: 'https://api-masterclass.jci.cc',
      url: '/web/oauthToken',
      method: 'POST',
      data,
      encryptionType: 1,
      authorizeType: 1
    })
  },

  jciAuthorizeRecord (data) {
    return request({
      url: '/api/jciAuthorizeRecord',
      method: 'POST',
      data
    })
  },

  //  编辑用户信息
  editUser (data) {
    return request({
      url: '/api/editUser',
      method: 'POST',
      data
    })
  },
  //  获取用户未读信息
  UnreadMessage (data) {
    return request({
      url: '/api/UnreadMessage',
      method: 'POST',
      data
    })
  },

  //  系统消息列表
  systemList (data) {
    return request({
      url: '/api/systemList',
      method: 'POST',
      data
    })
  },
  //  全部系统消息列表
  allSystemList (data) {
    return request({
      url: '/api/allSystemList',
      method: 'POST',
      data
    })
  },
  //  删除系统消息
  delSystemList (data) {
    return request({
      url: '/api/delSystemList',
      method: 'POST',
      data
    })
  },

  /// //////////////////// 登录日志  ///////////////////
  // 登录日志列表
  loginLogList (params) {
    return request({
      url: '/api/loginLogList',
      method: 'GET',
      params
    })
  },
  /// //////////////////// 隐私通知设置  ///////////////////
  // 设置支付密码
  editUsers (data) {
    return request({
      url: '/api/editUsers',
      method: 'POST',
      data,
      encryptionType: 2
    })
  },
  // 获取隐私设置
  getPrivacy (data) {
    return request({
      url: '/api/getPrivacy',
      method: 'POST',
      data
    })
  },
  // 修改隐私设置
  PrivacySet (data) {
    return request({
      url: '/api/PrivacySet',
      method: 'POST',
      data
    })
  },
  // 获取通知设置
  getNotification (data) {
    return request({
      url: '/api/getNotification',
      method: 'POST',
      data
    })
  },
  // 修改通知设置
  notificationSet (data) {
    return request({
      url: '/api/notificationSet',
      method: 'POST',
      data
    })
  },
  // 获取验证码
  sandEditCode (data) {
    return request({
      url: '/api/sandEditCode',
      method: 'POST',
      data
    })
  },
  // 修改邮箱
  editEmail (data) {
    return request({
      url: '/api/editEmail',
      method: 'POST',
      data
    })
  },
  // /////////   ///////// 教育经历   ///////////////////////
  // 新增教育经历
  addEducation (data) {
    return request({
      url: '/api/addEducation',
      method: 'POST',
      data
    })
  },
  // 编辑教育经历
  editEducation (data) {
    return request({
      url: '/api/editEducation',
      method: 'POST',
      data
    })
  },
  // 删除教育经历      ////    ===》 暂未调用
  delEducation (data) {
    return request({
      url: '/api/delEducation',
      method: 'POST',
      data
    })
  },
  // 教育经历列表
  educationList (params) {
    return request({
      url: '/api/educationList',
      method: 'GET',
      params
    })
  },
  // 获取单条教育经历
  educationFirst (data) {
    return request({
      url: '/api/educationFirst',
      method: 'POST',
      data
    })
  },
  // 标签
  UserTag (data) {
    return request({
      url: '/api/UserTag',
      method: 'POST',
      data
    })
  },
  /// //////////// ////////  个人爱好   ///////////
  // 个人爱好列表
  userHobbies (params) {
    return request({
      url: '/api/userHobbies',
      method: 'GET',
      params
    })
  },
  // 添加爱好
  addHobbies (data) {
    return request({
      url: '/api/addHobbies',
      method: 'POST',
      data
    })
  },
  // 删除爱好
  delHobbies (data) {
    return request({
      url: '/api/delHobbies',
      method: 'POST',
      data
    })
  },

  // /////////////////  个人职称 ////////////
  professionalTitlesList (data) {
    return request({
      url: '/api/professionalTitlesList',
      method: 'POST',
      data
    })
  },
  // /////////////////  好友操作 ///////////////
  // 获取好友列表
  userFriendList (data) {
    return request({
      url: '/api/userFriendList',
      method: 'POST',
      data
    })
  },
  // 发起好友申请
  friendRequest (data) {
    return request({
      url: '/api/friendRequest',
      method: 'POST',
      data
    })
  },
  // 获取好友所属分会数量
  getUserFriendTotal (data) {
    return request({
      url: '/api/getUserFriendTotal',
      method: 'POST',
      data
    })
  },
  // 删除好友
  delFriend (data) {
    return request({
      url: '/api/delFriend',
      method: 'POST',
      data
    })
  },

  // ///////////// ///////   KYC操作     ////
  // 我的kyc信息///
  myKyc (data) {
    return request({
      url: '/api/myKyc',
      method: 'POST',
      data
    })
  },
  // 新增kyc身份
  addKyc (data) {
    return request({
      url: '/api/addKyc',
      method: 'POST',
      data
    })
  },
  // 编辑kyc身份
  editKyc (data) {
    return request({
      url: '/api/editKyc',
      method: 'POST',
      data
    })
  },
  // 获取kyc列表
  kycList (data) {
    return request({
      url: '/api/kycList',
      method: 'POST',
      data
    })
  },

  //   ////////////      会员       ///////////////////
  // 撤销会员申请
  delVip (data) {
    return request({
      url: '/api/delVip',
      method: 'POST',
      data
    })
  },
  // 修改会员组织
  editVip (data) {
    return request({
      url: '/api/editVip',
      method: 'POST',
      data
    })
  },
  // 查询会员状态
  applyVipStatus (data) {
    return request({
      url: '/api/applyVipStatus',
      method: 'POST',
      data
    })
  },
  // 申请成为会员
  applyVip (data) {
    return request({
      url: '/api/applyVip',
      method: 'POST',
      data
    })
  },
  // 支付金额成为会员        ------>>暂未调用
  payVipPrice (data) {
    return request({
      url: '/api/payVipPrice',
      method: 'POST',
      data
    })
  },
  // ////////// /////// //// /特殊身份 ////////////////////
  // 商家类型
  getProductClassify (data) {
    return request({
      url: '/api/getProductClassify',
      method: 'POST',
      data
    })
  },
  // 申请特殊身份
  addApply (data) {
    return request({
      url: '/api/addApply',
      method: 'POST',
      data
    })
  },
  // 编辑申请信息
  editApply (data) {
    return request({
      url: '/api/editApply',
      method: 'POST',
      data
    })
  },
  // 删除申请信息
  delApply (data) {
    return request({
      url: '/api/delApply',
      method: 'POST',
      data
    })
  },
  // 申请角色列表
  applyList (params) {
    return request({
      url: '/api/applyList',
      method: 'GET',
      params
    })
  },
  // ////////////////////银行卡信息 ////////
  // 新增银行卡
  addBank (data) {
    return request({
      url: '/api/addBank',
      method: 'POST',
      data
    })
  },
  // 编辑银行卡信息
  editBank (data) {
    return request({
      url: '/api/editBank',
      method: 'POST',
      data
    })
  },
  // 删除银行信息
  delBank (data) {
    return request({
      url: '/api/delBank',
      method: 'POST',
      data
    })
  },
  cancelKyc (data) {
    return request({
      url: '/api/cancelKyc',
      method: 'POST',
      data
    })
  },
  // 获取单条银行卡信息
  getBankFirst (data) {
    return request({
      url: '/api/getBankFirst',
      method: 'POST',
      data
    })
  },
  // 用户获取主页帖子列表
  getUserPostList (data) {
    return request({
      url: '/api/getUserPostList',
      method: 'POST',
      data
    })
  },
  // 获取频道
  myChannel (data) {
    return request({
      url: 'api/myChannel',
      method: 'POST',
      data
    })
  },
  // 取消关注频道
  cancelChannel (data) {
    return request({
      url: '/api/cancelChannel',
      method: 'POST',
      data
    })
  },
  // 相册列表
  userAlbum (data) {
    return request({
      url: '/api/allAlbums',
      method: 'POST',
      data
    })
  },
  // 获取相册图片
  getAlbumImage (data) {
    return request({
      url: '/api/albumPhotos',
      method: 'POST',
      data
    })
  },
  // 视频列表
  getVideo (data) {
    return request({
      url: '/api/allVideos',
      method: 'POST',
      data
    })
  },
  addVideo (data) {
    return request({
      url: '/api/addVideo',
      method: 'POST',
      data
    })
  },
  delVideos (data) {
    return request({
      url: '/api/delVideos',
      method: 'POST',
      data
    })
  },
  ceshi (params) {
    return request({
      url: '/api/ceshi',
      method: 'GET',
      params
    })
  },
  // 申请职称
  applyJobTitle (data) {
    return request({
      url: '/api/applyJobTitle',
      method: 'POST',
      data
    })
  },
  // 申请职称列表
  applyJobTitleList (params) {
    return request({
      url: '/api/applyJobTitleList',
      method: 'GET',
      params
    })
  },
  // 职称列表
  titleList (data) {
    return request({
      url: '/api/titleList',
      method: 'POST',
      data
    })
  },
  // 删除一条申请
  titleDel (data) {
    return request({
      url: '/api/titleDel',
      method: 'POST',
      data
    })
  },

  // ----------------会员会费订单------------
  // 会员订单列表
  vipOrderList (data) {
    return request({
      url: '/api/vipOrderList',
      method: 'POST',
      data
    })
  },

  // 删除会员订单
  delVipOrder (data) {
    return request({
      url: '/api/delVipOrder',
      method: 'POST',
      data
    })
  },

  // 添加照片到相册
  addPhoto (data) {
    return request({
      url: '/api/addPhoto',
      method: 'POST',
      data
    })
  },

  // 删除相册中的图片(单/批量)
  delPhotos (data) {
    return request({
      url: '/api/delPhotos',
      method: 'POST',
      data
    })
  },

  /* ---------修改后的職稱---------- */
  // 获取职称
  getJobTitle (params) {
    return request({
      url: '/api/getJobTitle',
      params
    })
  },

  // 获取用户职称记录
  getUserJobTitleLog (params) {
    return request({
      url: '/api/getUserJobTitleLog',
      params
    })
  },

  // 获取用户职称简单版
  getUserJobTitleSingle (params) {
    return request({
      url: '/api/getUserJobTitleSingle',
      params
    })
  },

  // 添加用户职称
  addUserJobTitle (data) {
    return request({
      url: '/api/addUserJobTitle',
      method: 'POST',
      data
    })
  }

}
