import request from "../request";
export default {
  // 捐赠记录
  getDonateRecord(data) {
    return request({
      url: "/api/getDonateRecord",
      method: "POST",
      data
    });
  },
  //搜索组织
  organizationSearch(data) {
    return request({
      url: "/api/organizationSearch",
      method: "POST",
      data
    });
  },
  // 新增捐赠
  addDonate(data){
    return request({
      url: "/api/addDonate",
      method: "POST",
      data
    });
  },

  // 捐款排行
  jciDonationRank(data) {
    return request({
      url: "/api/jciDonationRank",
      method: "POST",
      data
    });
  },
  // 实时捐款记录
  jciNewDonation(data) {
    return request({
      url: "/api/jciNewDonation",
      method: "POST",
      data
    });
  },
  // 我的捐赠
  getMyDonation(data) {
    return request({
      url: "/api/getMyDonation",
      method: "POST",
      data
    });
  },
  // 捐款
  donate(data) {
    return request({
      url: "/api/donate",
      method: "POST",
      data
    });
  },
  // 捐款搜索
  donateSearch(data) {
    return request({
      url: "/api/donateSearch",
      method: "POST",
      data
    });
  },
  // JCI基金会介绍
  getJciFoundation(data) {
    return request({
      url: "/api/getJciFoundation",
      method: "POST",
      data
    });
  },
  // JCI董事会
  getBoardOfDirectors(data) {
    return request({
      url: "api/getBoardOfDirectors",
      method: "POST",
      data
    });
  },
  // JCI捐赠等级
  getDonorLevel(data) {
    return request({
      url: "/api/getDonorLevel",
      method: "POST",
      data
    });
  },

  // 捐款记录
  getDonateRecord(data) {
    return request({
      url: "/api/getDonateRecord",
      method: "POST",
      data
    });
  },

  // 获取其他组织
  getOtherOrganizationList(data){
    return request({
      url: "/api/getOtherOrganizationList",
      method: "POST",
      data
    });
  },

  // PayPal支付成功
  donatePayPalCheckoutSuccess(data){
    return request({
      url: "/api/donatePayPalCheckoutSuccess",
      method: "POST",
      data
    });
  },

  // 获取捐赠订单列表
  getDonateOrderList(data){
    return request({
      url: "/api/getDonateOrderList",
      method: "POST",
      data
    });
  },

  // 获取捐赠订单详情
  getDonateOrderDetail(data){
    return request({
      url: "/api/getDonateOrderDetail",
      method: "POST",
      data
    });
  },

  // 删除捐赠订单
  delDonateOrder(data){
    return request({
      url: "/api/delDonateOrder",
      method: "POST",
      data
    });
  },

  // 获取捐赠手续费
  getDonationCommission(data){
    return request({
      url: "/api/getDonationCommission",
      method: "POST",
      data
    });
  },

};
